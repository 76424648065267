import React from "react"
import MainNav from "../components/mainnav"

const RegistryPage = () => (
  <div className="md:bg-white bg-white text-black">
    <div className="container max-w-full md:static sticky top-0 md:mb-20 mb-8 mx-auto items-center text-center border-b-1 border-sand shadow-2xl">
      <MainNav />
    </div>
    <div className="container overflow-auto lg:flex mx-auto items-center justify-center border-8 border-white rounded-lg shadow-2xl">
      <div className="lg:pl-10 mr-5">
        <p className="lg:text-left text-center md:text-6xl text-4xl mb-0 font-serif">
          Registry
        </p>
        <p className="lg:text-left text-center mt-0 text-left text-4xl lg:mb-0 mb-10">
          __
        </p>

        <p className="mb-5 text-lg">
          We’ve been asked what we’d like for a gift and the answer is simple:
          you, at our wedding, having a great time whether in person or
          virtually. The biggest gift to us is your attendance at the
          celebration of our marriage. We are both so blessed and fortunate to
          have everything we want and need.
        </p>
        <p className="mb-5 text-lg">
          However, if you really wish to provide a gift, we would be honored if
          you would consider contributing to our honeymoon fund. We would love
          to have the honeymoon of a lifetime and if you’d like to help us
          please make a super-easy and secure card payment directly to any of
          the links below.
        </p>
        <p className="mb-5 text-lg">
          There is absolutely no requirement or pressure to do so! We cannot
          state strongly enough: being able to share our wedding ceremony with
          you is the greatest gift in the world!
        </p>
        <div className="sm:flex lg:mt-20 my-5">
          <a href="https://www.paypal.me/bac001" className="mx-auto">
            <img
              src="../../paypal-logo.png"
              alt="PaypalLink"
              className="mx-auto w-20"
            ></img>
          </a>
          <a href="https://venmo.com/Yasser-Nadeem" className="mx-auto">
            <img
              src="../../venmo-logo.png"
              alt="VenmoLink"
              className="mx-auto w-20"
            ></img>
          </a>
          <a href="https://cash.app/$AliciaDantin" className="mx-auto">
            <img
              src="../../cash-app-logo.png"
              alt="CashAppLink"
              className="mx-auto w-20"
            ></img>
          </a>
        </div>
      </div>
      <img
        src="../../Alicia-227.jpg"
        alt="Main"
        className="object-contain w-1/2 lg:pr-2 mx-auto shadow-2xl border-0 border-white rounded-lg justify-center"
      ></img>
    </div>
    {/* Grid of photos */}
    <div className="md:grid md:grid-flow-row md:grid-cols-3 grid-cols-1 md:grid-rows-2 mt-20 gap-0">
      {/* Row 1 (engagement photos) */}
      <img
        src="../../Alicia-156.jpg"
        alt="Grid Top Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-160.jpg"
        alt="Grid Top Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-236.jpg"
        alt="Grid Top Right"
        className="container w-full max-h-full mx-auto"
      ></img>
      {/* Row 2 (engagement photos) */}
      <img
        src="../../Alicia-57.jpg"
        alt="Grid Top Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-72.jpg"
        alt="Grid Top Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-135.jpg"
        alt="Grid Top Right"
        className="container w-full max-h-full mx-auto"
      ></img>
      {/* Row 3 (engagement photos) */}
      <img
        src="../../Alicia-385.jpg"
        alt="Grid Top Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-310.jpg"
        alt="Grid Top Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../Alicia-336.jpg"
        alt="Grid Top Right"
        className="container w-full max-h-full mx-auto"
      ></img>
      {/* Row 4 (non-engagement photos) */}
      <img
        src="../../montross.jpg"
        alt="Grid Top Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../engagement-ring.jpg"
        alt="Grid Top Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../doggies.jpg"
        alt="Grid Top Right"
        className="container w-full max-h-full mx-auto"
      ></img>
      {/* Row 5 (non-engagement photos) */}
      <img
        src="../../proposal-2.jpg"
        alt="Grid Bottom Left"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../topgolf.jpg"
        alt="Grid Bottom Middle"
        className="container w-full max-h-full mx-auto"
      ></img>
      <img
        src="../../christmas-ring.jpg"
        alt="Grid Bottom Right"
        className="container w-full max-h-full mx-auto"
      ></img>
    </div>
  </div>
)

export default RegistryPage
