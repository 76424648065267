import React from "react"

import { Link } from "gatsby"

const MainNav = () => {
  return (
    <nav className="md:bg-transparent bg-white md:relative lg:px-16 px-0 md:flex md:flex-wrap lg:py-2 py-0 h-full w-full z-10 font-typewriter">
      <ul className="md:flex-1 md:flex md:ml-0 lg:justify-between items-center md:text-2xl text-lg">
        <div className="md:order-3">
          <li className="">
            <Link to="/">
              <img
                src="../../logo-2.png"
                alt="Logo"
                className="mx-auto md:w-56 w-32 pb-1 pt-0"
              ></img>
            </Link>
          </li>
        </div>
        <div className="md:order-first">
          <li className="hover:underline md:px-5 mx-auto md:no-underline underline">
            <Link to="/ourstory">Our Story</Link>
          </li>
        </div>
        <div className="md:order-1">
          <li className="hover:underline md:px-5 md:no-underline underline">
            <Link to="/virtual">Livestream Attendees</Link>
          </li>
        </div>
        <div className="order-4">
          <li className="hover:underline md:px-5 md:no-underline underline">
            <Link to="/inperson">Local Family Attendees</Link>
          </li>
        </div>
        <div className="order-5">
          <li className="hover:underline md:px-5 md:no-underline underline">
            <Link to="/registry">Registry</Link>
          </li>
        </div>
      </ul>
    </nav>
  )
}

export default MainNav
